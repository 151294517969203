<template>
  <div class="wrap">
    <!--顶部面包靴-->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/volunteer/integralSystem' }" class="fs20">积分体系</el-breadcrumb-item>
      <el-breadcrumb-item class="fs20">{{type===1?'新增':type===2?'编辑':'详情'}}</el-breadcrumb-item>
    </el-breadcrumb>
    <!--表单-->
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="rule-form">
      <el-row>
        <el-form-item label="体系名称" prop="systemName">
          <el-input v-model="ruleForm.systemName" class="w360" placeholder="请输入" :disabled="disabledFlag"></el-input>
        </el-form-item>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="体系描述" prop="systemDescription">
            <el-input type="textarea" v-model="ruleForm.systemDescription" placeholder="请输入" :rows="20"
              :disabled="disabledFlag">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item class="btns">
        <el-button @click="back">返回</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')" v-if="type!==3">{{type===1?'提交':'修改'}}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { addIntegralSystem, updateIntegralSystem } from '@/api/integralSystem'
export default {
  components: {},
  data() {
    return {
      ruleForm: {
        systemName: '',
        systemDescription: ''
      },
      rules: {
        systemName: [
          { required: true, message: '请输入体系名称', trigger: 'blur' }
        ],
        systemDescription: [
          { required: true, message: '请输入体系描述', trigger: 'blur' }
        ]
      },
      type: 1, // 1新增 2编辑 3详情
      disabledFlag: false
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.type = Number(this.$route.query.type)
      if (this.type === 2 || this.type === 3) {
        this.info = JSON.parse(this.$route.query.info)
        this.ruleForm = this.info
      }
      if (this.type === 3) {
        this.disabledFlag = true
      }
    },
    async addIntegralSystem() {
      const res = await addIntegralSystem(this.ruleForm)
      this.$message.success(res.data)
      this.back()
    },
    async updateIntegralSystem() {
      this.ruleForm.id = this.info.id
      const res = await updateIntegralSystem(this.ruleForm)
      this.$message.success(res.data)
      this.back()
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.type === 1) {
            this.addIntegralSystem()
          } else {
            this.updateIntegralSystem()
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    back() {
      this.$router.push({ path: '/volunteer/integralSystem' })
    },
    resetForm(formName) {
      this.$refs[formName].resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>
.wrap {
  box-sizing: border-box;
  background-color: #ffffff;
  padding: 20px;
  // height: calc(100%);
}
.title {
  font-size: 18px;
  font-weight: 500;
  color: #333;
  margin-bottom: 20px;
}
.ml20 {
  margin-left: 20px;
}
.w360 {
  width: 360px;
}
.fs20 {
  font-size: 14px;
}
.rule-form {
  margin: 30px 0 0 30px;
}
// .el-input, .el-select, .el-date-editor{
//   width: 230px;
// }
.img-wrap {
  width: 120px;
  height: 120px;
  border-radius: 6px;
}
.btns {
  padding-left: 120px;
}
</style>
</style>
